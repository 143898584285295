<template>
  <v-app style="background-color: white!important">
    <v-content>
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <h1 class="display-4 font-weight-light text-center" style="color: #F19953">403</h1>
            <h4 class="text-center grey--text my-6">ඔබගේ ලියාපදිංචිය තවම අනුමත කර නැත</h4>
            <div class="text-center mt-12">
              <v-btn
                @click="$router.push('/')"
                class="text-center"
                color="primary"
                dark
                elevation="5"
                rounded
              >
                <v-icon class="mr-3">mdi-arrow-left</v-icon>මුල් පිටුවට
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { UserService } from "../services/user.service";

export default {
  mounted: function () {
    // Logout user
    if (UserService.isLoggedIn()) {
      try {
        UserService.logout(0);
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>